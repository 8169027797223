import React, { useState } from 'react';
import JSZip from 'jszip';
import { Button, Modal, message } from 'antd';
import { DownloadOutlined } from "@ant-design/icons";
import { useFirestore } from 'react-redux-firebase';
import { useDocuments } from './context';

const DownloadFolder = ({ folder, handleFolderClick }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const { folders: contextFolders } = useDocuments();
  const firestore = useFirestore();
  const customerRoot = contextFolders.routes.data[0].name;

  function getFolderNameById(id, items) {
    const folder = items.find(f => f.id === id);
    return folder ? folder.name : null;
  }

  function groupFilesByLocation(files) {
    const root = {};
    files.forEach(file => {
      let current = root;
      const path = file.location;
      path.forEach((folder, index) => {
        if (!current[folder]) {
          current[folder] = index === path.length - 1 ? [] : {};
        }

        if (index === path.length - 1) {
          current[folder].push(file);
        } else {
          current = current[folder];
        }
      });
    });
    return root;
  }

  async function addFilesToZip(zip, folderPath, files, items) {
    let processedFiles = 0;
    const totalFiles = countTotalFiles(files);

    for (const key in files) {
      if (Array.isArray(files[key])) {
        for (const file of files[key]) {
          try {
            const response = await fetch(file.urlDescarga);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const pdfBlob = await response.blob();
            const fileName = file.name;
            zip.folder(folderPath).file(fileName, pdfBlob);
            
            processedFiles++;
            setProgress(Math.round((processedFiles / totalFiles) * 100));
          } catch (error) {
            message.warning(`No se pudo descargar: ${file.name}`);
          }
        }
      } else {
        const translatedFolderName = getFolderNameById(key, items);
        if (translatedFolderName) {
          const newFolderPath = folderPath ? `${folderPath}/${translatedFolderName}` : translatedFolderName;
          await addFilesToZip(zip, newFolderPath, files[key], items);
        } else {
          await addFilesToZip(zip, folderPath, files[key], items);
        }
      }
    }
  }

  const countTotalFiles = (files) => {
    let count = 0;
    for (const key in files) {
      if (Array.isArray(files[key])) {
        count += files[key].length;
      } else {
        count += countTotalFiles(files[key]);
      }
    }
    return count;
  };

  async function downloadFolder() {
    try {
      setIsDownloading(true);
      setIsModalVisible(true);
      setProgress(0);

      const items = await handleFolderClick(
        folder.reference.path.collection(folder.reference.id)
      );

      const folderRef = firestore.collection('files');
      const currentFolderId = folder.reference.id;
      
      const folderSnapshot = await folderRef
        .where('customer', '==', customerRoot)
        .get();

      const allFiles = [];

      folderSnapshot.forEach(doc => {
        const data = doc.data();
        const fullPath = data.folder.f_.path.segments;
        const folderIndex = fullPath.findIndex(segment => segment === currentFolderId);

        if (folderIndex !== -1) {
          allFiles.push({
            name: data.name,
            url: fullPath.join('/'),
            urlDescarga: data.url,
            location: fullPath.slice(folderIndex)
          });
        }
      });

      if (allFiles.length === 0) {
        throw new Error('No se encontraron archivos en esta carpeta');
      }

      const groupedFiles = groupFilesByLocation(allFiles);
      const zip = new JSZip();

      await addFilesToZip(zip, "", groupedFiles, items);

      const zipBlob = await zip.generateAsync({ 
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: { level: 6 }
      });

      const url = URL.createObjectURL(zipBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${folder.name || 'carpeta'}.zip`;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      message.success('Descarga completada');
    } catch (error) {
      message.error(error.message || 'Error al descargar la carpeta');
    } finally {
      setIsDownloading(false);
      setIsModalVisible(false);
      setProgress(0);
    }
  }

  return (
    <>
      <Button 
        type="link"
        style={{ paddingLeft: 0 }}
        onClick={downloadFolder}
        disabled={isDownloading}
      >
        <DownloadOutlined style={{ marginRight: 8 }} />
        {isDownloading ? 'Descargando...' : 'Descargar carpeta'}
      </Button>

      <Modal
        title="Descarga en progreso"
        visible={isModalVisible}
        footer={null}
        closable={false}
      >
        <div>
          <p>
            La descarga de la carpeta está en progreso. 
            Este proceso puede demorar algunos minutos.
          </p>
          <div className="progress-bar">
            <div 
              style={{ 
                width: `${progress}%`,
                height: '20px',
                backgroundColor: '#1890ff',
                borderRadius: '4px',
                transition: 'width 0.3s ease-in-out'
              }} 
            />
          </div>
          <p>{progress}% completado</p>
        </div>
      </Modal>
    </>
  );
};

export default DownloadFolder;
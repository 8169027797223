/**
 * Controla la configuración de la aplicación
 */

export const settings = {

  // firebase config

  firebase: {
    config: {
      apiKey: "AIzaSyAqmGB0gpMlBMODMmBaWUhyeJzZYy89vTs",
      authDomain: "daysi-variadores.firebaseapp.com",
      databaseURL: "https://daysi-variadores.firebaseio.com",
      projectId: "daysi-variadores",
      storageBucket: "daysi-variadores.appspot.com",
      messagingSenderId: "1029607004999",
      appId: "1:1029607004999:web:4e9de7fa6e0784eaa98a62",
      measurementId: "G-M2NE7KLKMR"
    }
  },

  // react-redux-firebase config

  reactReduxFirebase: {
    config: {
      userProfile: 'users',
      useFirestoreForProfile: true
    }
  }

}